.Yir2022 {
  background: #141414;
  color: white;
}

.App {
  text-align: center;
  font-family: "Fjalla One";
}

a {
  color: #fff;
}

.swirl_path {
  stroke-dasharray: 2000;
  stroke-dashoffset: 2000;
  animation: dash 5s linear alternate infinite;
}

@keyframes dash {
  from {
    stroke-dashoffset: 2000;
  }
  to {
    stroke-dashoffset: 0;
  }
}

.fader {
  opacity: 1;
  animation: fade 2s linear infinite;
}

@keyframes fade {
  0%,
  100% {
    opacity: 0.25;
  }
  50% {
    opacity: 1;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.slick-next {
  right: 8px !important;
  z-index: 10;
}
.slick-prev {
  left: 8px !important;
  z-index: 10;
}
.slick-next.slick-disabled,
.slick-prev.slick-disabled {
  display: none !important;
}
.slick-dots li button:before {
  color: white !important;
}

.MuiToggleButton-root {
  color: #000 !important;
  font-size: 16px !important;
}
.MuiToggleButton-root.Mui-selected {
  background: #f05537 !important;
  color: #fff !important;
  font-weight: 700;
}
